/* DON'T EDIT THIS FILE: edit original and run build again */ import { DataPipe } from "../../data-pipe/data-pipe.ts";
import { colPipe } from "../../firebase/firestore-pipe.ts";
import {
  canCreateInviteLinks,
  canCreateProducerInviteLinks,
} from "../../permission/permissions.ts";
import { Roles, RolesMap } from "../../permission/schema.ts";
import { userHasPermission } from "../../permission/user-has-permission.ts";
import { getInviteLinksCol } from "./data.ts";
import { PENDING } from "./status.ts";

export const createListPendingUserInvitesPipe = ({
  roles,
  tenant,
  filters,
}: {
  roles: RolesMap;
  tenant: string;
  filters: {
    showInvites?: boolean;
  };
}) => {
  const canInviteAny = userHasPermission(roles, canCreateInviteLinks);
  const canInviteProducers = userHasPermission(
    roles,
    canCreateProducerInviteLinks
  );
  if (!filters.showInvites || (!canInviteAny && !canInviteProducers)) {
    return DataPipe.withInitialData(null, []);
  }
  let col = getInviteLinksCol().where("tenant", "==", tenant);
  if (!canInviteAny) {
    col = col.where("requestedRole", "==", Roles.producer);
  }
  col = col.where("status", "==", PENDING);
  return colPipe(col, "id", "listPendingUserInvitesPipe").pipe((inviteLinks) =>
    inviteLinks.map((inviteLink) => ({
      ...inviteLink,
      roles: [inviteLink.requestedRole],
    }))
  );
};
