/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  useCurrentTenant,
  useRealmFromUrl,
} from "../app-state/app-state-context.ts";
import { getTenantHandler } from "../tenant-collection/tenant.ts";
import { matchfinTenant } from "../tenant-matchfin-code/matchfin-tenant-code.ts";
import { setTenantCodeFromUrl } from "../url-parsing/parse-tenant-from-url.ts";
import { useCallback, useEffect } from "react";
import {
  Link,
  LinkProps,
  NavigateOptions,
  To,
  generatePath,
  useLocation,
  useNavigate,
} from "react-router-dom";

const maybeAddTenantToPath = (path: string, tenantLabel: string | null) => {
  if (path.startsWith("/") && !path.startsWith("/t/")) {
    // is absolute and does not already have tenant
    return "/t/" + (tenantLabel as string) + path;
  }
  return path;
};

const tenantRouting = (to: number | To, tenant: string, realm: string) => {
  const label = getTenantHandler(tenant).getPathLabel();
  const isMultiTenant = allowMultiTenantRouting(realm, tenant);
  if (typeof to === "number" || !isMultiTenant) {
    return to;
  } else if (typeof to === "string" || to instanceof String) {
    return maybeAddTenantToPath(to.toString(), label);
  } else {
    return {
      ...to,
      pathname: maybeAddTenantToPath(to.pathname ?? "", label),
    };
  }
};

export const usePreviousPath = () => {
  return (fallbackPath: string) => {
    // we use the idx to check if the history stack is empty
    // i.e if the user accesed the page directly by typing the url
    const isHistoryStackEmpty = window.history.state?.idx === 0;
    if (isHistoryStackEmpty) {
      return {
        to: fallbackPath,
        options: { replace: true },
      };
    }
    return {
      to: -1,
      options: { replace: true },
    };
  };
};

export const useNavigateInTenant = () => {
  const realm = useRealmFromUrl();
  const tenant = useCurrentTenant(true); // we do not want to keep routing inside an invalid tenant
  const navigate = useNavigate();
  return useCallback(
    (to: number | To, options?: NavigateOptions | undefined) => {
      setTenantCodeFromUrl(tenant);
      navigate(tenantRouting(to, tenant, realm) as To, options);
    },
    [tenant, realm, navigate]
  );
};

export type BackPath = { to: To; options?: { replace?: boolean } };

export const LinkInTenant = ({
  to,
  ...props
}: { to: number | To } & Omit<LinkProps, "to">) => {
  const realm = useRealmFromUrl();
  const tenant = useCurrentTenant(true); // we do not want to keep routing inside an invalid tenant
  return (
    <Link
      to={tenantRouting(to, tenant, realm) as To}
      onClick={() => setTenantCodeFromUrl(tenant)}
      {...props}
    />
  );
};

export const NavigateInTenant = ({
  to,
  ...props
}: { to: number | To } & NavigateOptions) => {
  const navigate = useNavigateInTenant();
  useEffect(() => {
    navigate(to, props);
  }, [to, props, navigate]);
  return null;
};

export const RedirectIntoTenant = () => {
  const { pathname, search, hash, state } = useLocation();
  return (
    <NavigateInTenant
      to={{ pathname, search, hash }}
      replace={true}
      state={{ fromRedirectIntoTenant: true, ...state }}
    />
  );
};

export const getLoginRedirectPath = ({
  pathname,
  search,
  hash,
  routeProps,
  state,
}: {
  pathname: string;
  search: string;
  hash: string;
  routeProps: any;
  state: any;
}) => {
  const effectivePath = state?.fromRedirectIntoTenant
    ? pathname.replace(/\/t\/[a-z-]+\//, "/")
    : pathname;
  return (
    "/login?redirect=" + generatePath(effectivePath + search + hash, routeProps)
  );
};

export const allowMultiTenantRouting = (realm: string, tenant: string) =>
  realm === matchfinTenant && tenant !== matchfinTenant;
