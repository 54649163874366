/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { globalmarketTenant } from "./code.ts";
import { globalmarketFullLabel } from "./full-label.ts";
import { globalmarketOnboardingOptions } from "./globalmarket-onboarding-options.ts";
import { globalmarketLabel } from "./label.ts";

class GlobalMarketTenantHandler extends AlycTenantHandler {
	async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
		// @ts-expect-error the hook is auto generated
		return (await import("./logo_globalmarket.svg?react")).default;
	}

	getCode(): string {
		return globalmarketTenant;
	}

	getPathLabel(): string | null {
		return "globalmarket";
	}

	getLabel(): string {
		return globalmarketLabel;
	}

	getFullLabel(): string {
		return globalmarketFullLabel;
	}

	getLineOptions(): BaseLineOptions | null {
		return null;
	}

	getOnboardingOptions(): OnboardingOptions | null {
		return globalmarketOnboardingOptions;
	}

	getNotificationsFrom(): string {
		return "no-responder@matchfin.ar";
	}

	getComplianceEmailAddress(): string {
		return "backoffice@globalmarketsa.com.ar";
	}

	getCuit(): string {
		return "30-71024960-8";
	}

	getCnvRegistryId(): string | null {
		return "550";
	}

	publishesInMarketplace(): boolean {
		return true;
	}
}

const globalmarketTenantHandler = new GlobalMarketTenantHandler();

export default globalmarketTenantHandler;
