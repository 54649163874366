/* DON'T EDIT THIS FILE: edit original and run build again */ import { useIsInPdf } from "../pdf-wrapper/context.ts";
import { CSSProperties, MouseEventHandler } from "react";

type IconProps = {
  width?: number;
  height?: number;
  size?: number;
  style?: CSSProperties;
  onClick?: MouseEventHandler<SVGSVGElement>;
  onMouseEnter?: MouseEventHandler<SVGSVGElement>;
  onMouseLeave?: MouseEventHandler<SVGSVGElement>;
  tabIndex?: number;
  color?: string;
  fill?: string;
  className?: string;
};

type IconMap = Record<string, React.ComponentType<IconProps>>;

let pdfIconMap: IconMap, webIconMap: IconMap;

export const setPdfIconMap = (value: IconMap) => {
  pdfIconMap = value;
};

export const setWebIconMap = (value: IconMap) => {
  webIconMap = value;
};

export const createGenericIcon =
  (name: string) =>
  ({
    width,
    height,
    size = 16,
    ...props
  }: IconProps & {
    size?: number;
  }) => {
    const isInPdf = useIsInPdf();
    const Icon = isInPdf ? pdfIconMap[name] : webIconMap[name];
    return (
      <Icon
        {...props}
        width={width ?? size}
        height={height ?? size}
        data-icon={name}
      />
    );
  };
