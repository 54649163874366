/* DON'T EDIT THIS FILE: edit original and run build again */ import {
	emailChannelType,
	whatsappChannelType,
} from "../../channel/channel-types.ts";
import {
	AFIP,
	EMAIL,
} from "../../signatura-document-validations/document-validations.ts";
import { BaseOnboardingOptions } from "../../tenant-handlers/base-onboarding-options.ts";
import { ComponentType } from "react";

class GlobalMarketOnboardingOptions extends BaseOnboardingOptions {
	hasSignatura(): boolean {
		return true;
	}

	getSignaturaValidations(): string[] {
		return [EMAIL, AFIP];
	}

	hasAune(): boolean {
		return true;
	}

	hasHumanDirectShareholders(): boolean {
		return false;
	}

	getSupportedChannelTypes(): string[] {
		return [emailChannelType, whatsappChannelType];
	}

	getEmailAfterLabelText(): string | null {
		return (
			"Este mail será detallado en el PUC de Caja de Valores " +
			"(Consulta Online de saldos y movimientos de la cuenta corriente)."
		);
	}

	async getDocumentHeader(): Promise<
		ComponentType<{ agreementLabel: string }>
	> {
		return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
	}

	hasOwnedEntityField(): boolean {
		return false;
	}

	hasEstimatedAmountToInvest(): boolean {
		return true;
	}

	hasAssemblyAttendanceDocument(): boolean {
		return true;
	}

	hasPositionFieldRequired(): boolean {
		return true;
	}

	hasHasNoFinalBeneficiariesField(): boolean {
		return false;
	}

	getSnailMailSignatureAddress(): string {
		return "Mendoza 31 Sur PB c.p. 5400";
	}
}

export const globalmarketOnboardingOptions =
	new GlobalMarketOnboardingOptions();
