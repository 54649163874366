/* DON'T EDIT THIS FILE: edit original and run build again */ import { RoleRequestStatus } from "../../auth-role-requests/schema.ts";
import { descSorter } from "../../core/sort-by-many.ts";
import { usersListDefaultSorters } from "../../users/list-sorters.ts";
import { UserListType } from "../../users/user-list-type.ts";
import { createListApproveUsersPipe } from "./data-pipe.ts";
import { userHasPermissionToListApproveUsers } from "./permissions.ts";

export const approveUsersTypeId = "approveUsers";

export const approveUsersType: UserListType = {
  id: approveUsersTypeId,
  hasPermission: ({ roles }) => userHasPermissionToListApproveUsers(roles),
  dataPipe: createListApproveUsersPipe as any,
  sorters: [
    ...Object.values(RoleRequestStatus).map((status) =>
      descSorter((user: any) => (user.roleRequest.status === status ? 1 : 0))
    ),
    ...usersListDefaultSorters,
  ],
};
