/* DON'T EDIT THIS FILE: edit original and run build again */ import { Div } from "../pdf-wrapper/pdf-wrapper.tsx";
import { CircledNumber } from "./circled-number.tsx";

export const DarkTitle = ({
  number,
  className = "",
  children,
}: {
  number?: number;
  className?: string;
  children?: React.ReactNode;
}) => (
  <Div
    className={
      "my-4 border-bottom-gray50 flex-row text-l-compact d-flex pb-1 " +
      className
    }
  >
    {number ? <CircledNumber number={number} /> : null}
    <Div className="flex-grow-1">{children}</Div>
  </Div>
);
