/* DON'T EDIT THIS FILE: edit original and run build again */ import { LineOptions } from "../tenant-handler-type/line-options.ts";

export class BaseLineOptions implements LineOptions {
  getAmountOptionValuesInMillionsOfPesos(): number[] {
    return [
      3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
      23, 24, 25, 26, 27, 30, 40, 50, 100, 150, 200, 300, 400, 500,
    ];
  }

  getUsdAmountOptionValues(): number[] {
    return [];
  }

  getLastAmountIsEqualOrGreater(): boolean {
    return false;
  }

  getPostBalanceMonthCount(): number {
    return 36;
  }

  hasFinancingTermInMonths(): boolean {
    return false;
  }

  hasInstruments(): boolean {
    return true;
  }

  canSeePreApprovedStatus(): boolean {
    return false;
  }

  endClientsCanRequestMatchfinAnalystsReview(): boolean {
    return false;
  }

  canChooseAlyc(): boolean {
    return false;
  }

  hasCustomQuantAnalysis(): boolean {
    return false;
  }
}
