/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  matchfinSupportEmail,
  matchfinSupportWhatsapp,
  matchfinSupportWhatsappUnformatted,
} from "../contact-information/matchfin-support.ts";
import { MessageBuilderType } from "./message-builder-type.front.ts";


export const AskForSupportMessage = ({
  askConditional = "el error persiste",
}) => (
  // we should maybe have an email and whatsapp support for each realm ?
  <>
    Si {askConditional}, por favor comunicate por mail a{" "}
    <a href={"mailto:" + matchfinSupportEmail}>{matchfinSupportEmail}</a> o por
    whatsapp al{" "}
    <a
      href={
        "https://api.whatsapp.com/send?phone=" +
        matchfinSupportWhatsappUnformatted
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      {matchfinSupportWhatsapp}
    </a>
    .
  </>
);

export const DefaultErrorMessageDescription = () => (
  <>
    Hubo un problema, por favor volvé a intentar. <AskForSupportMessage />
  </>
);

export const ErrorMessageDescription = ({
  builder,
  code,
}: {
  builder: MessageBuilderType;
  code: string;
}) => {
  const { message = <DefaultErrorMessageDescription />, showCode = true } =
    builder;
  return (
    <>
      {message}
      {showCode ? (
        <span className="text-muted">
          <br />
          {"código de error: " + code}
        </span>
      ) : null}
    </>
  );
};
