/* DON'T EDIT THIS FILE: edit original and run build again */ import { EntityType } from "../gov-id-number/entity-type.ts";
import { EMAIL } from "../signatura-document-validations/document-validations.ts";
import { OnboardingOptions } from "../tenant-handler-type/onboarding-options.ts";
import { ComponentType } from "react";

export class BaseOnboardingOptions implements OnboardingOptions {
  hasSignatura(): boolean {
    return false;
  }

  hasEsco(): boolean {
    return false;
  }

  hasAune(): boolean {
    return false;
  }

  hasRevenueVolume(): boolean {
    return true;
  }

  hasGenderField(): boolean {
    return true;
  }

  hasVideoSelfie(): boolean {
    return true;
  }

  hasFullGeneralInfoSection(): boolean {
    return true;
  }

  hasShareholders(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return true;
  }

  hasOwnedEntityField(): boolean {
    return true;
  }

  finalBeneficiariesPercentage(): number {
    return 10;
  }

  usesUruguayLaw(): boolean {
    return false;
  }

  hasAccounts(): boolean {
    return true;
  }

  hasInvestmentProfile(): boolean {
    return true;
  }

  hasRelatedEntitiesSection(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  getSupportedChannelTypes(): string[] {
    return [];
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este mail será detallado en el PUC de Caja de Valores (Consulta Online " +
      "de saldos y movimientos de la cuenta corriente) y para enviar " +
      "información sobre movimientos y/o resúmenes de cuenta (Régimen " +
      "informativo)"
    );
  }

  useSnailMailViaEmail(): boolean {
    return true;
  }

  getSnailMailSignatureAddress(): string | null {
    return null;
  }

  supportsChannelType(channelType: string): boolean {
    return this.getSupportedChannelTypes().includes(channelType);
  }

  supportsWebHomeChannelAccessType(): boolean {
    return false;
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [];
  }

  async getEmailHeader(): Promise<ComponentType<Record<string, never>>> {
    return () => null;
  }

  hasSwornStatementAboutLegality(): boolean {
    return false;
  }

  hasProofOfFciRulebook(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return false;
  }

  hasFatca(): boolean {
    return true;
  }

  hasMatbaRofex(): boolean {
    return true;
  }

  hasSgrComissions(): boolean {
    return true;
  }

  getBeneficiaryEntityType(): string {
    return EntityType.Any;
  }

  hasCorporateAdminSection(): boolean {
    return false;
  }

  hasOnboardingCoholdersSection(): boolean {
    return true;
  }

  hasTaxStatusOfRepresentatives(): boolean {
    return true;
  }

  hasOptionToNotUploadDocuments(): boolean {
    return true;
  }

  hasMutualGuaranteeContract(): boolean {
    return false;
  }

  hasTerms(): boolean {
    return true;
  }

  hasSwornStatement(): boolean {
    return false;
  }

  hasBeneficiaryCanBePublic(): boolean {
    return true;
  }

  hasBeneficiaryDoc(): boolean {
    return true;
  }

  hasIsQualifiedInvestorText(): boolean {
    return false;
  }

  allowPersonalAccount(): boolean {
    return true;
  }

  allowForeignCompanies(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return () => null;
  }

  usesFinancialGuarantee(): boolean {
    return false;
  }

  getOpenAccountAgreementLabel(): string {
    return "Convenio de apertura";
  }

  getOnboardingLabel(): string {
    return "Apertura de Cuenta";
  }

  getOnboardingMailLabel(): string {
    return "apertura de cuenta comitente";
  }

  canBackupOnboardings(): boolean {
    return false;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return false;
  }

  hasPositionFieldRequired(): boolean {
    return false;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return true;
  }

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "automatic";
  }
}
