/* DON'T EDIT THIS FILE: edit original and run build again */ import { getCountryOptions } from "../../country/countries.ts";
import { SimpleDropdown, SimpleDropdownProps } from "./simple-dropdown.tsx";

export const CountryDropdown = ({
  value,
  options = getCountryOptions(),
  ...props
}: SimpleDropdownProps) => (
  <SimpleDropdown value={value} options={options} {...props} />
);
