/* DON'T EDIT THIS FILE: edit original and run build again */ import { emailChannelType } from "../../channel/channel-types.ts";
import { BaseOnboardingOptions } from "../../tenant-handlers/base-onboarding-options.ts";
import { ComponentType } from "react";

class BrioOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  hasAune(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [emailChannelType];
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este mail será detallado en el PUC de Caja de Valores " +
      "(Consulta Online de saldos y movimientos de la cuenta corriente)."
    );
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }

  supportsWebHomeChannelAccessType(): boolean {
    return true;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "both";
  }
}

export const brioOnboardingOptions = new BrioOnboardingOptions();
