/* DON'T EDIT THIS FILE: edit original and run build again */ import { spaceship } from "../core/spaceship.ts";
// @ts-expect-error - no types available
import { getCountries } from "country-list-spanish";

let countryOptions: Record<string, string>;

export const getCountryOptions = () => {
  if (!countryOptions) {
    const entries = Object.entries(getCountries({ object: true }));
    entries.sort((a, b) => spaceship(a[1], b[1]));
    countryOptions = Object.fromEntries(entries) as Record<string, string>;
  }
  return countryOptions;
};

export const getCountryLabel = (code: string) => getCountryOptions()[code];
