/* DON'T EDIT THIS FILE: edit original and run build again */ import { adminRole } from "../../permission/all-roles.ts";
import {
  canListAssignedEndClientProfiles,
  canListExternalTenantProfiles,
  canListInternalTenantProfiles,
  canListProducerTenantProfiles,
} from "../../permission/permissions.ts";
import { RolesMap } from "../../permission/schema.ts";
import { userHasPermission } from "../../permission/user-has-permission.ts";
import { matchfinTenant } from "../../tenant-matchfin-code/matchfin-tenant-code.ts";

export const userHasPermissionToListExternalTenantUsers = (roles: RolesMap) =>
  userHasPermission(roles, canListExternalTenantProfiles);

export const userHasPermissionToListInternalTenantUsers = (roles: RolesMap) =>
  userHasPermission(roles, canListInternalTenantProfiles);

export const userHasPermissionToListProducerTenantUsers = (roles: RolesMap) =>
  userHasPermission(roles, canListProducerTenantProfiles);

export const userHasPermissionToListAssignedEndClientUsers = (
  roles: RolesMap
) => userHasPermission(roles, canListAssignedEndClientProfiles);

export const userHasPermissionToListMatchfinSpecificUsers = (
  roles: RolesMap,
  currentTenant: string
) => currentTenant === matchfinTenant && roles[adminRole];
