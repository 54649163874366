/* DON'T EDIT THIS FILE: edit original and run build again */ import { getProfileDocument } from "../../auth-profile/profile.ts";
import { getRoleRequestsCol } from "../../auth-role-requests/role-request.ts";
import { RoleRequestStatus } from "../../auth-role-requests/schema.ts";
import { DataPipe } from "../../data-pipe/data-pipe.ts";
import { joinPipe } from "../../data-pipe/join-pipe.ts";
import { colPipe, docPipe } from "../../firebase/firestore-pipe.ts";

export const createListApproveUsersPipe = ({
  tenant,
  filters,
}: {
  tenant: string;
  filters?: { showPending: boolean; showIgnored: boolean };
}) => {
  if (!filters?.showPending && !filters?.showIgnored) {
    return DataPipe.withInitialData(null, []);
  }
  let col = getRoleRequestsCol();
  col = col.where("tenant", "==", tenant);
  col = col.where("status", "in", [
    ...(filters?.showPending ? [RoleRequestStatus.pending] : []),
    ...(filters?.showIgnored ? [RoleRequestStatus.ignored] : []),
  ]);
  col = col.where("isEndClient", "==", false);
  return joinPipe(
    colPipe(col, "uid", "approveRoleRequestsPipe"),
    ({ uid }) => docPipe(getProfileDocument(uid), "uid"),
    (parent, child) => {
      return {
        roleRequest: parent,
        ...child,
        roles: parent.requestedRoles,
      };
    }
  );
};
