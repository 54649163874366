/* DON'T EDIT THIS FILE: edit original and run build again */ // @ts-expect-error app-routes.js is generated
import { appRoutes } from "../app-routes.js";
import { SimplePageNotFoundPage } from "../theme-pages/page-not-found.front.tsx";
import { Route, Routes } from "react-router-dom";
import { RouteWrapper } from "./route-wrapper.front.tsx";

type AppRoute = {
  path: string;
  Component: React.ComponentType<any>;
  requires?: {
    account?: boolean;
    permission?: string;
    tenant?: boolean;
  };
  redirectOnNoAccount?: string;
  hardcodedProps?: any;
  isMatchfinSpecific?: boolean;
};

export const FlatRouting = ({ currentTenant }: { currentTenant: string }) => {
  return (
    <Routes>
      {(appRoutes as AppRoute[]).map(
        ({
          path,
          Component,
          requires,
          redirectOnNoAccount,
          hardcodedProps,
          isMatchfinSpecific,
        }) => (
          <Route
            key={path}
            path={path}
            element={
              <RouteWrapper
                currentTenant={currentTenant}
                path={path}
                Component={Component}
                requires={requires}
                redirectOnNoAccount={redirectOnNoAccount}
                hardcodedProps={hardcodedProps}
                isMatchfinSpecific={isMatchfinSpecific}
              />
            }
          />
        )
      )}
      <Route key={404} path="*" element={<SimplePageNotFoundPage />} />
    </Routes>
  );
};
