/* DON'T EDIT THIS FILE: edit original and run build again */ import { getRealmFromCurrentHostAlone } from "../realm/get-realm-from-current-host.ts";
import {
  nullTenant,
  unknownTenant,
} from "../tenant-collection/null-tenant.ts";
import { getTenantCodeFromPathLabel } from "../tenant-collection/tenant.ts";
import { matchfinTenant } from "../tenant-matchfin-code/matchfin-tenant-code.ts";

const parseTenantLabelFromUrl = () => {
  const realmFromHost = getRealmFromCurrentHostAlone();
  // allow t/tenant only in matchfin domain
  if (realmFromHost === matchfinTenant) {
    const path = window.location.pathname;
    const regex = /\/t\/([a-z-]+)(\/|$)/;
    const match = regex.exec(path);
    if (match) {
      return match[1];
    }
  }
  return null;
};

const calculateTenantCodeFromUrl = () => {
  const tenantLabel = parseTenantLabelFromUrl();
  if (tenantLabel === null) {
    return nullTenant;
  }
  const maybeTenantFromLabel = getTenantCodeFromPathLabel(tenantLabel);
  if (maybeTenantFromLabel !== null) {
    return maybeTenantFromLabel;
  }
  return unknownTenant;
};

let currentTenantCodeFromUrl: string;

export const getTenantCodeFromUrl = () => {
  if (!currentTenantCodeFromUrl) {
    currentTenantCodeFromUrl = calculateTenantCodeFromUrl();
  }
  return currentTenantCodeFromUrl;
};

export const setTenantCodeFromUrl = (tenantCode: string) => {
  currentTenantCodeFromUrl = tenantCode;
};
