/* DON'T EDIT THIS FILE: edit original and run build again */ import { useNavigateInTenant } from "../routing/tenant-routing.tsx";
import React, { useContext, useEffect, useState } from "react";
import { NavigateOptions, To } from "react-router-dom";
import { createDefaultSectionValidator } from "./default-section-validator.ts";
import { multiValidator } from "./multi-validator.ts";
import { SectionCollectionUtil } from "./section-collection-util.ts";
import { SectionContext } from "./section-context.ts";
import { SectionWarningState } from "./section-warning-state.tsx";
import { Section, SectionCollection } from "./section.tsx";

export abstract class BaseSectionNavigation<
  TSectionContext extends SectionContext
> {
  abstract next(): Promise<void>;
  abstract previous(): Promise<void>;
  abstract hasPrevious(): boolean;
  abstract hasNext(): boolean;
  abstract go(
    mustValidate: boolean,
    goCallback: () => Promise<void> | void
  ): Promise<void>;
  abstract addAdditionalValidator(
    name: string,
    validator: () => string | null
  ): void;
  abstract getCurrentPageId(): string;
  abstract getSections(): readonly Section<TSectionContext>[];
  abstract setAsDirty(): void;
}

class SectionNavigation<
  TSectionContext extends SectionContext
> extends BaseSectionNavigation<TSectionContext> {
  private sectionId;
  private additionalValidators: {
    [name: string]: () => string | null;
  } = {};
  private validator: () => string | null;
  private sectionUtil: SectionCollectionUtil<TSectionContext>;

  constructor(
    private sectionCol: SectionCollection<TSectionContext>,
    path: string,
    private sectionWarningState: SectionWarningState,
    private afterValidator: () => string | null = () => null,
    private dirty: boolean,
    private setDirty: (dirty: boolean) => void,
    private navigate: (
      to: number | To,
      options?: NavigateOptions | undefined
    ) => void
  ) {
    super();
    this.sectionUtil = new SectionCollectionUtil(this.sectionCol);
    const section = this.sectionUtil.findAnySection({ path });
    this.sectionId = section.getId();
    this.validator = createDefaultSectionValidator(section, sectionCol);
  }

  private handleValidationError(
    error: string,
    { goCallback }: { goCallback: () => void }
  ) {
    this.sectionWarningState.setShowWarning(true);
    this.sectionWarningState.setValidatorError(error);
    // careful, useState takes a function, so the outer function is called immediately
    this.sectionWarningState.setGoToPageCallback(() => goCallback);
  }

  private async goToPage(mustValidate: boolean, goCallback: () => void) {
    if (mustValidate && this.dirty) {
      const error = multiValidator(
        this.validator,
        ...Object.values(this.additionalValidators),
        this.afterValidator
      )();
      // we ignore errors when not going forwards, just discard changes on errors
      if (error) {
        this.handleValidationError(error, { goCallback });
        return;
      }
    }
    await goCallback();
  }

  private goToSectionId(pageId: string) {
    const newSection = this.sectionUtil.findAnySection({ sectionId: pageId });
    return this.navigate(
      this.sectionUtil.getSectionPath(
        pageId,
        newSection.getEditModeIsPreferred(this.sectionCol)
      ),
      { replace: true }
    );
  }

  private getNextSectionId(pageId: string) {
    return this.sectionUtil.getNextSectionId(pageId);
  }

  private getPrevSectionId(pageId: string) {
    return this.sectionUtil.getPrevSectionId(pageId);
  }

  private async validateAndGoToSectionId(
    mustValidate: boolean,
    sectionId: string
  ) {
    await this.goToPage(mustValidate, () => this.goToSectionId(sectionId));
  }

  next() {
    return this.validateAndGoToSectionId(
      true,
      this.getNextSectionId(this.sectionId) as string
    );
  }

  previous() {
    return this.validateAndGoToSectionId(
      false,
      this.getPrevSectionId(this.sectionId) as string
    );
  }

  hasPrevious() {
    return !!this.getPrevSectionId(this.sectionId);
  }

  hasNext() {
    return !!this.getNextSectionId(this.sectionId);
  }

  go(mustValidate: boolean, goCallback: () => Promise<void>) {
    return this.goToPage(mustValidate, goCallback);
  }

  addAdditionalValidator(name: string, validator: () => string | null) {
    this.additionalValidators[name] = validator;
  }

  getCurrentPageId(): string {
    return this.sectionId;
  }

  getSections(): readonly Section<TSectionContext>[] {
    return this.sectionCol.sections;
  }

  setAsDirty(): void {
    this.setDirty(true);
  }
}

class NoSectionNavigationError extends Error {
  constructor() {
    super("noSectionNavigationError");
  }
}

class DummySectionNavigation extends BaseSectionNavigation<SectionContext> {
  next(): Promise<void> {
    throw new NoSectionNavigationError();
  }

  previous(): Promise<void> {
    throw new NoSectionNavigationError();
  }

  hasPrevious(): boolean {
    return false;
  }

  hasNext(): boolean {
    return false;
  }

  go(
    _mustValidate: boolean,
    _goCallback: () => Promise<void> | void
  ): Promise<void> {
    throw new NoSectionNavigationError();
  }

  addAdditionalValidator(_name: string, _validator: () => string | null): void {
    throw new NoSectionNavigationError();
  }

  getCurrentPageId(): string {
    throw new NoSectionNavigationError();
  }

  getSections(): readonly Section[] {
    throw new NoSectionNavigationError();
  }

  setAsDirty(): void {
    throw new NoSectionNavigationError();
  }
}

const dummySectionNavigation = new DummySectionNavigation();

export const useSectionNavigation = <TSectionContext extends SectionContext>({
  sectionCol,
  id,
  path,
  subpath,
  sectionWarningState,
  afterValidator = () => null,
}: {
  sectionCol: SectionCollection<TSectionContext>;
  id: string;
  path: string;
  subpath: string | null;
  sectionWarningState: SectionWarningState;
  afterValidator?: () => string | null;
}) => {
  const [dirty, setDirty] = useState(false);
  useEffect(() => setDirty(false), [id, path, subpath]);
  const navigate = useNavigateInTenant();
  return new SectionNavigation(
    sectionCol,
    path,
    sectionWarningState,
    afterValidator,
    dirty,
    setDirty,
    navigate
  );
};

export const SectionNavigationContext = React.createContext<
  BaseSectionNavigation<SectionContext>
>(dummySectionNavigation);

export const useGetSectionNavigation = () =>
  useContext(SectionNavigationContext);

export function useAlsoSetAsDirty<T extends any[]>(
  saver: (...args: T) => Promise<void> | void
): (...args: T) => Promise<void> {
  const sectionNavigation = useGetSectionNavigation();
  return async (...args: T) => {
    sectionNavigation.setAsDirty();
    return saver(...args);
  };
}
