/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../tenant-handlers/sgr-tenant-handler.ts";
import { cuyoTenant } from "./code.ts";
import { cuyoOnboardingOptions } from "./cuyo-onboarding-options.ts";
import { cuyoFullLabel } from "./full-label.ts";
import { cuyoLabel } from "./label.ts";

class CuyoTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return cuyoTenant;
  }

  getPathLabel(): string | null {
    return "cuyo-aval";
  }

  getLabel(): string {
    return cuyoLabel;
  }

  getFullLabel(): string | null {
    return cuyoFullLabel;
  }

  getOnboardingOptions() {
    return cuyoOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-70950628-1";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const cuyoTenantHandler = new CuyoTenantHandler();

export default cuyoTenantHandler;
