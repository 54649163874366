/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlertProvider } from "../alert/alert.tsx";
import { AppStateContext } from "../app-state/app-state-context.ts";
import { useDoc } from "../firebase/db.ts";
import { getRealmHostname } from "../realm/realm-url.ts";
import { getSettingsDoc } from "../settings/settings.ts";
import { getAllTenantCodes } from "../tenant-collection/tenant.ts";
import { SimplePageNotFoundPage } from "../theme-pages/page-not-found.front.tsx";
import { Loading } from "../theme/loading.tsx";
import { parseRealmFromUrl } from "../url-parsing/parse-realm-from-url.ts";
import { BrowserRouter as Router } from "react-router-dom";
import { AccountWrapper } from "./account-wrapper.front.tsx";
import { AppRouting } from "./app-routing.front.tsx";
import {
  ConnectionErrorPage,
  RealmDomainMismatchErrorPage,
  UndefinedRealmErrorPage,
} from "./error-pages.front.tsx";
import { MaintenanceWrapper } from "./maintenance-wrapper.front.tsx";
import { OrientationOverlay } from "./orientation-overlay.tsx";

const App = ({ realmFromUrl }: { realmFromUrl: string }) => {
  const [loading, settings, error] = useDoc(getSettingsDoc());

  if (loading) {
    return <Loading />;
  }
  if (error || !settings) {
    return <ConnectionErrorPage />;
  }
  return (
    <AppStateContext.Provider value={{ settings, realmFromUrl }}>
      <MaintenanceWrapper>
        <AccountWrapper>
          <AlertProvider />
          <AppRouting />
        </AccountWrapper>
      </MaintenanceWrapper>
    </AppStateContext.Provider>
  );
};

const RealmApp = ({ realmFromUrl }: { realmFromUrl: string }) => {
  if (realmFromUrl == null) {
    return <UndefinedRealmErrorPage />;
  }
  if (!getAllTenantCodes().includes(realmFromUrl)) {
    return <SimplePageNotFoundPage />;
  }
  if (getRealmHostname(realmFromUrl) !== window.location.hostname) {
    return <RealmDomainMismatchErrorPage realmFromUrl={realmFromUrl} />;
  }
  return <App realmFromUrl={realmFromUrl} />;
};

const AppWrapper = () => {
  const [basename, realmFromUrl] = parseRealmFromUrl();
  return (
    <Router basename={basename}>
      <OrientationOverlay realmFromUrl={realmFromUrl} />
      <RealmApp realmFromUrl={realmFromUrl} />
    </Router>
  );
};

export default AppWrapper;
