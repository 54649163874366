/* DON'T EDIT THIS FILE: edit original and run build again */ import { useIsInPdf } from "../../pdf-wrapper/context.ts";
import { Div } from "../../pdf-wrapper/pdf-wrapper.tsx";
import { CSSProperties } from "react";

const MaybeLabelTag = ({
  dontUseLabelTag,
  className = "",
  ...props
}: {
  dontUseLabelTag: boolean;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}) => {
  const isInPdf = useIsInPdf();
  if (isInPdf) {
    return <Div className={className} {...props} minPresenceAhead={2} />;
  } else if (dontUseLabelTag) {
    return (
      <div data-is-label className={"cursor-default " + className} {...props} />
    );
  } else {
    return <label data-is-label className={className} {...props} />;
  }
};

export const Labeled = ({
  label,
  afterLabel = null,
  labelClassName = "",
  input = null,
  optional,
  dontUseLabelTag = false,
  marginAfterLabel = !!input,
  className = "",
  style,
}: {
  label?: React.ReactNode;
  afterLabel?: React.ReactNode;
  labelClassName?: string;
  input?: React.ReactNode;
  optional?: boolean;
  dontUseLabelTag?: boolean;
  marginAfterLabel?: boolean;
  className?: string;
  style?: CSSProperties;
}): React.ReactNode => {
  // if there's no input we keep a margin of 1 (since the input will come as a separate div)
  return (
    <MaybeLabelTag
      dontUseLabelTag={dontUseLabelTag}
      className={
        "d-block " + className + " " + (marginAfterLabel ? "mb-5" : "mb-1")
      }
      style={style}
    >
      {label ? (
        <Div
          className={
            "d-flex flex-row flex-wrap " + (marginAfterLabel ? "mb-1" : "")
          }
        >
          <Div className={"fw-bold " + labelClassName}>{label}</Div>
          {optional ? (
            <Div className="ms-1 rounded bg-gray70 text-gray10 p-1 text-s">
              Opcional
            </Div>
          ) : null}
          {afterLabel}
        </Div>
      ) : null}
      <Div>{input}</Div>
    </MaybeLabelTag>
  );
};
