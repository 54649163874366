/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../attachment-type/attachment-ref-type.ts";
import { AttachmentItemMap } from "../attachment-ui/attachment-item.ts";
import { Gender } from "../gender/genders.ts";
import { StreetAddress } from "../street-address-type/street-address-type.ts";
import { AuneAgentLink } from "./aune-agent-link.ts";
import { FundsMediumMap } from "./funds-medium-type.ts";
import { FundsOfOriginMap } from "./funds-origin-type.ts";
import { InvestmentExperienceMap } from "./investment-experience-type.ts";
import { IsUsPerson } from "./is-us-person.ts";
import { MaritalStatus } from "./marital-status.ts";
import { PoliticalBond } from "./political-bond-type.ts";
import { VatCondition } from "./vat-condition.ts";

export const entityFieldMainEmail = "email";
export const entityFieldPhone = "phoneNumber";
export const entityFieldSecondaryPhone = "secondaryPhoneNumber";
export const entityFieldSecondaryEmail = "secondaryEmail";
export const entityFieldFirstFiscalStreetAddress = "firstFiscalStreetAddress";
export const entityFieldSecondFiscalStreetAddress = "secondFiscalStreetAddress";
export const entityFieldFax = "fax";
export const entityFieldPlaceOfIncorporation = "placeOfIncorporation";
export const entityFieldDateOfIncorporation = "dateOfIncorporation";
export const entityFieldFiscalStreetAddress = "fiscalStreetAddress";
export const entityFieldEntityName = "entityName";
export const entityFieldDni = "dni";
export const entityFieldCuit = "cuit";
export const entityFieldForeignIdNumber = "foreignIdNumber";
export const entityFieldCountry = "country";
export const entityFieldCommercialName = "commercialName";
export const entityFieldFirstName = "firstName";
export const entityFieldLastName = "lastName";
export const entityFieldDateOfBirth = "dateOfBirth";
export const entityFieldPlaceOfBirth = "placeOfBirth";
export const entityFieldNationality = "nationality";
export const entityFieldCountryOfResidence = "countryOfResidence";
export const entityFieldGender = "gender";
export const entityFieldAfipActivityCode = "afipActivityCode";
export const entityFieldEntityShortName = "entityShortName";
export const entityFieldNosisScore = "nosisScore";
export const entityFieldRevenueVolumeCurrency = "revenueVolumeCurrency";
export const entityFieldRevenueVolumeAmount = "revenueVolumeAmount";
export const entityFieldIsRequiredToInform = "isRequiredToInform";
export const entityFieldIsUsPerson = "isUsPerson";
export const entityFieldOpenMatbaRofexAccount = "openMatbaRofexAccount";
export const entityFieldAuthorizeSrgCommissionsAndExpenses =
  "authorizeSrgCommissionsAndExpenses";
export const entityFieldMainActivity = "mainActivity";
export const entityFieldBusinessSector = "businessSector";
export const entityFieldStatuteDate = "statuteDate";
export const entityFieldRegistryInscriptionDate = "registryInscriptionDate";
export const entityFieldRegistryNumber = "registryNumber";
export const entityFieldMaritalStatus = "maritalStatus";
export const entityFieldAlreadyAvailable = "documentationAlreadyAvailable";
export const entityFieldIsInsuranceCompany = "isInsuranceCompany";
export const entityFieldCurrentStatutoryLaw = "currentStatutoryLaw";
export const entityFieldStatute = "statute";
export const entityFieldBalanceSheets = "balanceSheets";
export const entityFieldFundsOrigin = "fundsOrigin";
export const entityFieldFundsMedium = "fundsMedium";
export const entityFieldShareholderMinutes = "shareholderMinutes";
export const entityFieldBoardMinutes = "boardMinutes";
export const entityFieldSrlShareholderMinutes = "srlShareholderMinutes";
export const entityFieldSrlBoardMinutes = "srlBoardMinutes";
export const entityFieldFinancialStatementsApprovalDocument =
  "financialStatementsApprovalDocument";
export const entityFieldAuthoritiesDesignationDocument =
  "authoritiesDesignationDocument";
export const entityFieldFinancialTrustType = "financialTrustType";
export const entityFieldTrustStatute = "trustStatute";
export const entityFieldTrustLastCertifiedBalanceSheet =
  "trustLastCertifiedBalanceSheet";
export const entityFieldTrustShareholderMinutes = "trustShareholderMinutes";
export const entityFieldTrustBoardMinutes = "trustBoardMinutes";
export const entityFieldGrantors = "grantors";
export const entityFieldAfipEarningsDocument = "afipEarningsDocument";
export const entityFieldAssemblyAttendanceDocument =
  "assemblyAttendanceDocument";
export const entityFieldSupportingDocumentation = "supportingDocumentation";
export const entityFieldSourceOfFundsDocuments = "sourceOfFundsDocuments";
export const entityFieldProofOfAddressDocument = "proofOfAddressDocument";
export const entityFieldDocumentationAlreadyAvailable =
  "documentationAlreadyAvailable";
export const entityFieldIsSme = "isSme";
export const entityFieldMipymeCertificates = "mipymeCertificates";
export const entityFieldSalesReports = "salesReports";
export const entityFieldHasNoFinancialDebts = "hasNoFinancialDebts";
export const entityFieldFinancialDebt = "financialDebtDocuments";
export const entityFieldExperienceLevel = "experienceLevel";
export const entityFieldInvestmentProfileProfile = "profile";
export const entityFieldInvestmentExperience = "investmentExperience";
export const entityFieldQualifiedInvestor = "isQualifiedInvestor";
export const entityFieldTypeOfQualifiedInvestor = "typeOfQualifiedInvestor";
export const entityFieldIncomeTaxCondition = "incomeTaxCondition";
export const entityFieldProfession = "profession";
export const entityFieldIncomeTaxStatements = "incomeTaxStatements";
export const entityFieldPropertyStatements = "propertyStatements";
export const entityFieldCashFlowDocuments = "cashFlowDocuments";
export const entityFieldIdFront = "idFront";
export const entityFieldIdBack = "idBack";
export const entityFieldIncomeProof = "incomeProof";
export const entityFieldExtraordinaryIncomeProof = "extraordinaryIncomeProof";
export const documentationItemFieldAttachment = "attachment";
export const documentationItemFieldDate = "date";
export const documentationItemFieldExpirationDate = "expirationDate";
export const documentationItemFieldDescription = "description";
export const entityFieldPosition = "position";
export const entityFieldFiscalId = "fiscalId";
export const entityFieldSecondFiscalId = "secondFiscalId";
export const entityFieldBusinessEntityType = "businessEntityType";
export const entityFieldInstitution = "institution";
export const entityFieldIsPoliticallyExposedPerson =
  "isPoliticallyExposedPerson";
export const entityFieldIsPoliticallyExposedByBond =
  "isPoliticallyExposedByBond";
export const entityFieldPoliticalPositionUruguay = "politicalPositionUruguay";
export const entityFieldPoliticalBondUruguay = "politicalBondUruguay";
export const entityFieldPoliticallyExposedPersonType =
  "politicallyExposedPersonType";
export const entityFieldPoliticallyExposedPersonDetail =
  "politicallyExposedPersonDetail";
export const entityFieldIsTraded = "isTraded";
export const entityFieldMarketOfShares = "marketOfShares";
export const entityFieldTradedSharesPercentage = "tradedSharesPercentage";
export const entityFieldNoTaxIdNumber = "noTaxIdNumber";
export const entityFieldTaxIdNumber = "taxIdNumber";
export const entityFieldWhyNoTaxId = "whyNoTaxId";
export const entityFieldWhyNoTaxIdOther = "whyNoTaxIdOther";
export const entityFieldOperatesOnOwn = "operatesOnOwn";
export const entityFieldThirdParties = "thirdParties";
export const entityFieldPowerDesignation = "powerDesignation";
export const entityFieldSwornStatementAboutLegality =
  "swornStatementAboutLegality";
export const entityFieldLossResponseWithdraw = "lossResponseWithdraw";
export const entityFieldHowMuchToInvest = "howMuchToInvest";
export const entityFieldEstimatedAmountToInvest = "estimatedAmountToInvest";
export const entityFieldEstimatedAmountToInvestCurrency =
  "estimatedAmountToInvestCurrency";
export const entityFieldOtherSwornStatement = "otherSwornStatement";
export const entityFieldVatCondition = "vatCondition";
export const entityFieldBusinessStartDate = "businessStartDate";
export const entityFieldAfipActivity = "afipActivity";
export const entityFieldEmployeeCount = "employeeCount";
export const entityFieldSecondaryActivity = "secondaryActivity";
export const entityFieldAuneAgentLink = "auneAgentLink";
export const entityFieldPartnerEntityId = "partnerEntityId";

// additionally we have "pep" + [Spouse, Parent, ParentSpouse, Child, ChildSpouse, Sibling] + field + [Name, Bond, Position]

export type DocumentationItem = {
  [documentationItemFieldAttachment]: AttachmentRef;
  [documentationItemFieldDate]?: string;
  [documentationItemFieldExpirationDate]?: string;
  [documentationItemFieldDescription]?: string;
};

export type DocumentationMap = {
  [id: string]: DocumentationItem;
};

export type InlineEntity = {
  id: string;
  // only used when no cuit is present (as cuit number defines either company or person)
  isCompany?: boolean;
  [entityFieldCountry]?: string;
  [entityFieldCuit]?: string;
  [entityFieldForeignIdNumber]?: string;
  [entityFieldBusinessEntityType]?: string;
  [entityFieldAfipActivity]?: string;
  [entityFieldIsUsPerson]?: IsUsPerson;
  [entityFieldGender]?: Gender;
  [entityFieldEntityName]?: string;
  [entityFieldFirstName]?: string;
  [entityFieldLastName]?: string;
  [entityFieldPhone]?: string;
  [entityFieldMainEmail]?: string;
  [entityFieldSecondaryPhone]?: string;
  [entityFieldSecondaryEmail]?: string;
  [entityFieldFirstFiscalStreetAddress]?: string;
  [entityFieldSecondFiscalStreetAddress]?: string;
  [entityFieldFax]?: string;
  [entityFieldPlaceOfIncorporation]?: string;
  [entityFieldMainActivity]?: string;
  [entityFieldBusinessSector]?: string;
  [entityFieldStatuteDate]?: string;
  [entityFieldRegistryInscriptionDate]?: string;
  [entityFieldRegistryNumber]?: number;
  [entityFieldFiscalStreetAddress]?: StreetAddress;
  [entityFieldDni]?: string;
  [entityFieldCommercialName]?: string;
  [entityFieldDateOfBirth]?: string;
  [entityFieldPlaceOfBirth]?: {
    locality?: string;
    argentineProvince?: string;
    otherProvince?: string;
    country: string;
  };
  [entityFieldNationality]?: string;
  [entityFieldCountryOfResidence]?: string;
  [entityFieldAfipActivityCode]?: number;
  [entityFieldEntityShortName]?: string;
  [entityFieldNosisScore]?: number;
  [entityFieldBusinessStartDate]?: string;
  [entityFieldMaritalStatus]?: MaritalStatus;
  [entityFieldIsInsuranceCompany]?: boolean;
  [entityFieldCurrentStatutoryLaw]?: AttachmentItemMap;
  [entityFieldStatute]?: AttachmentItemMap;
  [entityFieldBalanceSheets]?: DocumentationMap;
  [entityFieldFundsOrigin]?: FundsOfOriginMap;
  [entityFieldFundsMedium]?: FundsMediumMap;
  [entityFieldShareholderMinutes]?: AttachmentItemMap;
  [entityFieldBoardMinutes]?: AttachmentItemMap;
  [entityFieldSrlShareholderMinutes]?: AttachmentItemMap;
  [entityFieldSrlBoardMinutes]?: AttachmentItemMap;
  [entityFieldFinancialStatementsApprovalDocument]?: AttachmentItemMap;
  [entityFieldAuthoritiesDesignationDocument]?: AttachmentItemMap;
  [entityFieldFinancialTrustType]?: string;
  [entityFieldTrustStatute]?: AttachmentItemMap;
  [entityFieldTrustLastCertifiedBalanceSheet]?: AttachmentItemMap;
  [entityFieldTrustShareholderMinutes]?: AttachmentItemMap;
  [entityFieldTrustBoardMinutes]?: AttachmentItemMap;
  [entityFieldAfipEarningsDocument]?: AttachmentItemMap;
  [entityFieldAssemblyAttendanceDocument]?: AttachmentItemMap;
  [entityFieldSupportingDocumentation]?: AttachmentItemMap;
  [entityFieldSourceOfFundsDocuments]?: AttachmentItemMap;
  [entityFieldProofOfAddressDocument]?: AttachmentItemMap;
  [entityFieldDocumentationAlreadyAvailable]?: string;
  [entityFieldIsSme]?: boolean;
  [entityFieldMipymeCertificates]?: DocumentationMap;
  [entityFieldSalesReports]?: DocumentationMap;
  [entityFieldHasNoFinancialDebts]?: boolean;
  [entityFieldFinancialDebt]?: DocumentationMap;
  [entityFieldIsPoliticallyExposedPerson]?: boolean | null;
  [entityFieldRevenueVolumeCurrency]?: string;
  [entityFieldRevenueVolumeAmount]?: number;
  [entityFieldIsRequiredToInform]?: boolean;
  [entityFieldQualifiedInvestor]?: boolean;
  [entityFieldTypeOfQualifiedInvestor]?: string;
  [entityFieldVatCondition]?: VatCondition;
  [entityFieldEmployeeCount]?: number;
  [entityFieldIncomeTaxCondition]?: VatCondition;
  [entityFieldProfession]?: string;
  [entityFieldSecondaryActivity]?: string;
  [entityFieldIncomeTaxStatements]?: DocumentationMap;
  [entityFieldPropertyStatements]?: DocumentationMap;
  [entityFieldCashFlowDocuments]?: DocumentationMap;
  [entityFieldIdFront]?: AttachmentRef;
  [entityFieldIdBack]?: AttachmentRef;
  [entityFieldIncomeProof]?: AttachmentItemMap;
  anonymized?: boolean;
  [entityFieldExtraordinaryIncomeProof]?: AttachmentItemMap;
  [entityFieldPosition]?: string;
  [entityFieldFiscalId]?: string;
  [entityFieldSecondFiscalId]?: string;
  [entityFieldInstitution]?: string;
  [entityFieldIsTraded]?: boolean;
  [entityFieldMarketOfShares]?: string;
  [entityFieldTradedSharesPercentage]?: number;
  [entityFieldExperienceLevel]?: string;
  [entityFieldInvestmentExperience]?: InvestmentExperienceMap;
  [entityFieldInvestmentProfileProfile]?: string;
  [entityFieldDateOfIncorporation]?: string;
  [entityFieldOpenMatbaRofexAccount]?: string;
  [entityFieldAuthorizeSrgCommissionsAndExpenses]?: string;
  [entityFieldGrantors]?: {
    [id: string]: {
      entityId: string;
    };
  };
  [entityFieldIsPoliticallyExposedByBond]?: boolean;
  [entityFieldPoliticalPositionUruguay]?: string;
  [entityFieldPoliticalBondUruguay]?: { [K in PoliticalBond]?: true };
  [entityFieldPoliticallyExposedPersonType]?: string;
  [entityFieldPoliticallyExposedPersonDetail]?: string;
  [entityFieldNoTaxIdNumber]?: boolean;
  [entityFieldTaxIdNumber]?: string;
  [entityFieldWhyNoTaxId]?: string;
  [entityFieldWhyNoTaxIdOther]?: string;
  [entityFieldOperatesOnOwn]?: string;
  [entityFieldThirdParties]?: {
    [id: string]: {
      entityId: string;
    };
  };
  [entityFieldPowerDesignation]?: AttachmentItemMap;
  [entityFieldSwornStatementAboutLegality]?: Record<string, boolean>;
  [entityFieldLossResponseWithdraw]?: string;
  [entityFieldHowMuchToInvest]?: string;
  [entityFieldEstimatedAmountToInvest]?: number;
  [entityFieldEstimatedAmountToInvestCurrency]?: string;
  [entityFieldOtherSwornStatement]?: string;
  [entityFieldAuneAgentLink]?: AuneAgentLink;
  [entityFieldPartnerEntityId]?: string;
};
